<template>
  <!-- NAME[epic=销售] 单据销售明细 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="时间类型"
        >
          <el-option
            v-for="(i, idx) in timeTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <class-select
          ref="classSelect"
          @class-select-change="classChange"
        ></class-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select
          ref="brandSelect"
          :duoxuan="true"
          @brand-select-change="brandChange"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          style="width: 140px"
          placeholder="请选择业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="driver_id">
        <el-select
          v-model="form.driver_id"
          clearable
          placeholder="送货人"
          style="width: 140px"
        >
          <el-option
            v-for="(i, idx) in shrList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="goods_type">
        <el-select
          v-model="form.goods_type"
          clearable
          style="width: 140px"
          placeholder="销售类型"
        >
          <el-option
            v-for="(i, idx) in saleSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="goodsSelect"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="bill_code">
        <el-input
          v-model="form.bill_code"
          placeholder="单号或单据备注"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExportyone">导出</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExporty">导出1</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出2</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <i>
          说明:导出1增加了订单金额、应收款等，并按订单进行了单元格合并；导出2内容省略了条形码和单位换算，适合长时间段大量数据统计。
        </i>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :sortable="item.sort"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleOrderDetail(row.order_id)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else-if="item.label == '售价'">
            <span v-if="row.goods_price != row.sell_price" style="color: red">
              {{ row[item.prop] }}
            </span>
            <span v-else>{{ row[item.prop] }}</span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <sale-order-detail ref="saleOrderDetail"></sale-order-detail>
  </div>
</template>
<script>
  import _ from 'lodash'
  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getStaffList } from '@/api/setPrice'
  import { driverList, exportSaleDetail } from '@/api/advanceOrder'
  import { saleTypeList, saleDetailList } from '@/api/saleData'
  import { downloadFile, postAction } from '@/api/Employee'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import { day_n, day_ns } from '@/utils/Time'
  export default {
    name: 'Detail',
    components: {
      ClassSelect,
      BrandSelect,
      GoodsSearch,
      ClientSearch,
      saleOrderDetail,
    },
    data() {
      return {
        time: [],
        staffList: [],
        timeTypeSelect: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'arrive_at',
            name: '到货时间',
          },
          {
            id: 'delivery_at',
            name: '派单时间',
          },
          {
            id: 'pay_status_time',
            name: '结清时间',
          },
        ],
        shrList: [],
        saleSelect: [],
        loading: false,
        list: [],
        checkList: [
          '单号',
          '客户编码',
          '客户',
          '业务员',
          '送货员',
          '下单日期',
          '到货时间',
          '派单时间',
          '结清状态',
          '出货仓库',
          '商品编码',
          '商品名称',
          '规格',
          '条形码',
          '单位换算',
          '小单位数量',
          '数量',
          '单位',
          '原价',
          '售价',
          '金额',
          '类型',
          '单据备注',
          '生产日期',
          '毛利',
          '毛利率',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '130px',
          },
          {
            order: 2,
            label: '客户编码',
            prop: 'cust_id',
            width: '100px',
          },
          {
            order: 2.5,
            label: '客户',
            prop: 'cust_name',
            width: '100px',
          },
          {
            order: 3,
            label: '业务员',
            prop: 'staff_name',
            width: '80px',
          },
          {
            order: 4,
            label: '送货员',
            prop: 'driver_name',
            width: '80px',
          },
          {
            order: 5,
            label: '下单日期',
            prop: 'create_at',
            width: '160px',
          },
          {
            order: 6,
            label: '到货时间',
            prop: 'arrive_at',
            width: '160px',
          },
          {
            order: 6.2,
            label: '派单时间',
            prop: 'delivery_at',
            width: '160px',
          },
          {
            order: 6.3,
            label: '结清状态',
            prop: 'pay_status',
            width: '160px',
          },
          {
            order: 6.5,
            label: '出货仓库',
            prop: 'depot_name',
            width: '100px',
          },
          {
            order: 6.5,
            label: '商品编码',
            prop: 'goods_id',
            width: '110px',
          },
          {
            order: 7,
            label: '商品名称',
            prop: 'goods_name',
            width: '110px',
          },
          {
            order: 7.5,
            label: '规格',
            prop: 'goods_specs',
            width: '110px',
          },
          {
            order: 8,
            label: '条形码',
            prop: 'unit_code',
            width: '90px',
          },
          {
            order: 9,
            label: '单位换算',
            prop: 'goods_cv',
            width: '90px',
          },
          {
            order: 9.5,
            label: '小单位数量',
            prop: 'min_quantity',
            width: '110px',
          },
          {
            order: 10,
            label: '数量',
            prop: 'quantity',
            width: '70px',
          },
          {
            order: 11,
            label: '单位',
            prop: 'unit_name',
            width: '80px',
          },
          {
            order: 11.2,
            label: '原价',
            prop: 'sell_price',
            width: '70px',
          },
          {
            order: 12,
            label: '售价',
            prop: 'goods_price',
            width: '70px',
          },
          {
            order: 13,
            label: '金额',
            prop: 'goods_money',
            width: '70px',
          },
          {
            order: 14,
            label: '类型',
            prop: 'goods_type_text',
            width: '90px',
          },
          {
            order: 15,
            label: '单据备注',
            prop: 'order_remark',
            width: '160px',
          },
          {
            order: 16,
            label: '生产日期',
            prop: 'goods_production_date',
            width: '160px',
          },
          {
            order: 17,
            label: '毛利',
            prop: 'gross_profit_amount',
            width: '120px',
            sort: true,
          },
          {
            order: 18,
            label: '毛利率',
            prop: 'gross_profit',
            width: '120px',
            sort: true,
          },
        ],
        form: {
          time_type: 'arrive_at',
          start_time: '', //开始时间
          end_time: '', //结束时间
          class_id: '', //品类id
          brand_id: '', //品牌id
          staff_id: '', //业务员id
          driver_id: '', //送货员id
          goods_type: '', //类型id
          goods_id: '', //商品id
          cust_id: '', //客户id
          bill_code: '', //单据号
          pageNo: 1, //页数
          pageSize: 10, //条数
          order: '', //排序方法desc降序asc升序
          sort: '', //排序类型
        },
        totalAll: {},
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.initTime()
      this.fetchData()
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
      // 送货人下拉
      driverList().then((res) => {
        if (res.code == 200) {
          this.shrList = res.data
        }
      })
      // 销售类型下拉
      saleTypeList().then((res) => {
        this.saleSelect = res.data
      })
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      initTime() {
        this.time = day_n(90)
        this.form.start_time = this.time[0]
        this.form.end_time = this.time[1]
        // console.log('day_n(90)', day_n(90))
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await saleDetailList(this.form)
        this.total = totalCount
        this.list = data.list
        this.totalAll = data
        this.loading = false
      },
      classChange(val) {
        this.form.class_id = val
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      goodsSelect(val) {
        this.form.goods_id = val.goods_id
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 表格合计fun
      summaryFunction(param) {
        // 数量14 quantity    金额18 goods_money     毛利21goods_maoli
        let { columns, data } = param
        let sums = []
        columns.forEach((col, index) => {
          if (col.label == '毛利') {
            sums[index] = this.totalAll.goods_maoli
          } else if (col.label == '金额') {
            sums[index] = this.totalAll.goods_money
          } else if (col.label == '数量') {
            sums[index] = this.totalAll.quantity
          }
        })

        // sums[14] = this.totalAll.quantity
        // sums[18] = this.totalAll.goods_money
        // sums[21] = this.totalAll.goods_maoli
        // const { columns, data } = param
        // // 需要合计的下标
        // let i = [18]
        // let sums = []
        // // let val = data.map((item) => {
        // //   console.log(item)
        // // })
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => Number(item[col.property]))
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         sums[18] = a.toFixed(2) + ' 元'
        //       }
        //     }
        //   })
        //   if (idx == 0) {
        //     sums[idx] = '合计'
        //   }
        // })
        // console.log('sss', sums)
        return sums
      },
      handleExporty() {
        downloadFile(
          '/saleAdmin/sale-form/export-order',
          '销售明细(按单据).xlsx',
          this.form
        )
      },
      handleExportyone() {
        downloadFile(
          'saleAdmin/sale-form/export-order0',
          '销售明细(按单据)0.xlsx',
          this.form
        )
      },

      handleExport() {
        // downloadFile(
        //   '/saleAdmin/sale-form/export-order',
        //   '销售明细(按单据).xlsx',
        //   this.form
        // )
        postAction('/baseAdmin/export/add-export', {
          type: 5,
          ex_data: JSON.stringify(this.form),
        }).then((r) => {
          this.$message.success('导出中,请查看导出消息列表')
        })
        // exportSaleDetail(this.form).then((res) => {
        //   console.log('export', res)
        //   let a = document.createElement('a')
        //   a.style.display = 'none'
        //   let blob = new Blob([res], {
        //     type: 'application/vnd.ms-excel',
        //   })
        //   let url = URL.createObjectURL(blob)
        //   a.setAttribute('href', url)
        //   a.setAttribute('download', '销售明细(按单据).xlsx')
        //   a.click()
        //   document.body.removeChild(a)
        // })
      },
      handleOrderDetail(id) {
        this.$refs['saleOrderDetail'].isshowDialog = true
        this.$refs['saleOrderDetail'].id = id
      },
    },
  }
</script>
<style lang="scss" scoped></style>
